<i18n>
ru:
  favoriteAdd: Добавить в избранное
  favoriteRemove: Удалить из избранного
ua:
  favoriteAdd: Додати в обране
  favoriteRemove: Видалити з вибраного
us:
  favoriteAdd: Add to favorites
  favoriteRemove: Remove from favorites
</i18n>

<template>
  <common-popover
    v-if="!isSmall"
    class="v-menu-favorite"
    placement="top"
    @focusin="focusEvent(true)"
    @focusout="focusEvent(false)"
    @mouseenter="focusEvent(true)"
    @mouseleave="focusEvent(false)"
  >
    <template #hoverable>
      <span
        :class="[
          'v-menu-favorite__button',
          isFavorite ? 'v-menu-is-favorite' : 'v-menu-is-not-favorite'
        ]"
      >
        <menu-favorite-icon :broken="isHovering && isFavorite" />
      </span>
    </template>
    <template #content>
      <span
        class="v-menu-favorite-text"
        v-html="
          translate(
            isFavorite ? 'productFavoriteIcon.favoriteRemove' : 'productFavoriteIcon.favoriteAdd'
          )
        "
      />
    </template>
  </common-popover>
  <span
    v-else
    :class="['v-menu-favorite__button', { 'v-menu-is-favorite': isFavorite }]"
  >
    <menu-favorite-icon :broken="isHovering && isFavorite" />
  </span>
</template>

<script setup lang="ts">
import { type GUID, useWindowSize } from '@arora/common'

const { productId } = defineProps<{
  productId: GUID
}>()

const menuStore = useMenuStore()
const { AccountFavoritesMenu } = storeToRefs(menuStore)

const { translate } = useI18nSanitized()
const { isSmall } = useWindowSize()

const isFavorite = ref<boolean>(
  (AccountFavoritesMenu.value.data ?? []).some((product) => product.ID === productId)
)
const isHovering = ref<boolean>(false)

function focusEvent(isHover: boolean): void {
  isHovering.value = isHover
}

watch(
  () => AccountFavoritesMenu,
  () => {
    isFavorite.value = (AccountFavoritesMenu.value.data ?? []).some(
      (product) => product.ID === productId
    )
  },
  { deep: true }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-menu-favorite-text {
  text-align: center;
  display: flex;
}

.v-menu-favorite {
  &__button {
    background: variables.$BodyTextColorLight;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    text-align: center;
    @include mixins.trans;
    border-radius: 50%;
    padding: 6px;
    color: variables.$BodyElementsBackground;

    svg {
      position: absolute;
    }
  }
}

.v-menu-is-favorite {
  background: variables.$PrimaryBackgroundColor;
}

.v-menu-is-not-favorite {
  background: variables.$BodyTextColorLight;

  &:hover {
    background: variables.$PrimaryBackgroundColor;
  }
}
</style>
